export default {
  Header: {
    logoName: {
      ru: "Тестирование по Python",
      ua: "Тестування з Python",
    },
    question: {
      ru: "Вопрос",
      ua: "Питання",
    },
  },

  ButtonHideTheory: {
    text: {
      ru: "Скрыть теорию и задание",
      ua: "Приховати теорію і завдання",
    },
  },

  CodeEditors: {
    title: {
      ru: "Редактор Python:",
      ua: "Редактор Python:",
    },
  },

  CongratsModal: {
    title: {
      ru: "ПОЗДРАВЛЯЕМ!",
      ua: "ВІТАЄМО!",
    },
    text1_1: {
      ru: "Вы успешно прошли вступительное тестирование по ",
      ua: "Ви успішно пройшли вступне тестування з ",
    },
    text1_2: {
      ru: "java!",
      ua: "java!",
    },
    text2: {
      ru: " правильных ответов",
      ua: " правильних відповідей",
    },
    button: {
      ru: "Ок",
      ua: "Ок",
    },
  },

  ErrorInvalidTokenOrBlock: {
    text: {
      ru: "Не валидная ссылка.",
      ua: "Не валідне посилання.",
    },
  },

  ErrorServerStub: {
    text: {
      ru: "Технические работы на сервере, попробуйте снова позже.",
      ua: "Технічні роботи на сервері, спробуйте знову пізніше.",
    },
  },

  ErrorStub: {
    text: {
      ru: "Не валидная ссылка.",
      ua: "Не валідне посилання.",
    },
  },

  Footer: {
    text: {
      ru: "Политика конфиденциальности",
      ua: "Політика конфіденційності",
    },
  },

  MobileAndTabletStub: {
    text: {
      ru: "Расширь браузер или зайди с десктопа.",
      ua: "Розшир браузер або зайди з десктопа.",
    },
  },

  ModalAutotranslator: {
    title: {
      ru: "Выключите автопереводчик !!!",
      ua: "Вимкніть автоперекладач !!!",
    },
    text: {
      ru: "Потому что программа не сможет корректно проверить Ваше задание.",
      ua: "Тому що програма не зможе коректно перевірити Ваше завдання.",
    },
  },

  ModalPolitics: {
    title: {
      ru: "ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ",
      ua: "ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ",
    },
    text: {
      ru:
        "Согласившись принимать участие в проверке заданий вы предоставляете согласие на обработку персональных данных, соглашаетесь с предложенными нами каналами коммуникации, с возможностью получения рассылок и коммерческих предложений. Принимая данную политику вы также подтверждаете, что ознакомились с перечнем своих прав как субъекта данных.",
      ua:
        "Погодившись брати участь в марафоні ви надаєте згоду на обробку персональних даних, погоджуєтеся з запропонованими нами каналами комунікації, з можливістю отримання розсилок та комерційних пропозицій. Беручи дану політику ви також підтверджуєте, що ознайомилися з переліком своїх прав як суб'єкта даних.",
    },
    subtitle1: {
      ru: "1. СБОР ЛИЧНОЙ ИНФОРМАЦИИ",
      ua: "1. ЗБІР ОСОБИСТОЇ ІНФОРМАЦІЇ",
    },
    text1_1: {
      ru:
        "1.1. Во время заполнения контактных форм при регистрации мы просим вас сообщить необходимую личную информацию в объеме, который является необходимым для предоставления услуг.",
      ua:
        "1.1. Під час заповнення контактних форм при реєстрації ми просимо вас повідомити необхідну особисту інформацію в обсязі, який є необхідним для надання послуг.",
    },
    text1_2: {
      ru:
        "1.2. Файлы cookie - когда вы переходите по ссылкам, мы отправляем один или несколько файлов cookie на ваш компьютер или другое устройство. Файлы cookie используются для того, чтобы повышать качество предоставляемых услуг: сохранять настройки пользователя.",
      ua:
        "1.2. Файли cookie - коли ви переходите по посиланнях, ми відправляємо один або кілька файлів cookie на ваш комп'ютер або інший пристрій.Файли cookie використовуються для того, щоб підвищувати якість послуг,що надаються: зберігати налаштування користувача.",
    },
    text1_3: {
      ru:
        "1.3. Информация о посещении - при доступе на сайт наши серверы автоматически могут записывать определенную информацию. Эти журналы сервера могут содержать такую информацию, как веб-запрос, IP-адрес, тип и язык браузера, дату и время запроса.",
      ua:
        "1.3. Інформація про відвідування - при доступі на сайт наші сервери автоматично можуть записувати певну інформацію. Ці журнали сервера можуть містити таку інформацію, як веб-запит, IP-адреса, тип і мова браузера, дату і час запиту.",
    },

    subtitle2: {
      ru: "2. ЗАЩИТА ИНФОРМАЦИИ",
      ua: "2. ЗАХИСТ ІНФОРМАЦІЇ",
    },
    text2_1: {
      ru:
        "2.1. Мы принимаем все необходимые меры для защиты данных от неавторизованного доступа, изменения, раскрытия или уничтожения. К этим мерам относятся, в частности, внутренняя проверка процессов сбора, хранения и обработки данных и мер безопасности, включая соответствующее шифрование и меры по обеспечению физической безопасности данных для предотвращения неавторизованного доступа.",
      ua:
        "2.1. Ми вживаємо всіх необхідних заходів для захисту даних від несанкціонованого доступу, зміни, розкриття чи знищення. До цих заходів відносяться, зокрема, внутрішня перевірка процесів збору, зберігання і обробки даних і заходів безпеки, включаючи відповідне шифрування і заходи щодо забезпечення фізичної безпеки даних для запобігання несанкціонованого доступу.",
    },

    subtitle3: {
      ru: "3. ИЗМЕНЕНИЕ НАСТОЯЩЕЙ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ",
      ua: "3. ЗМІНА ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ",
    },
    text3_1: {
      ru:
        "3.1. Настоящая политика конфиденциальности может время от времени меняться. Изменения, вносимые в политику конфиденциальности, будут опубликованы в этом документе.",
      ua:
        "3.1. Дана політика конфіденційності може час від часу змінюватися.Зміни, що вносяться до політики конфіденційності будуть опубліковані в цьому документі.",
    },
  },

  ModalRefreshQuestion: {
    title: {
      ru: "Вы точно хотите сбросить код в начальное состояние ?",
      ua: "Ви точно хочете скинути код в початковий стан ?",
    },
    yes: {
      ru: "Да",
      ua: "Так",
    },
    no: {
      ru: "Нет",
      ua: "Ні",
    },
  },

  ResultArea: {
    title: {
      ru: "Результаты:",
      ua: "Результати:",
    },
    text: {
      ru: "(обновляются при нажатии на кнопку - Проверить):",
      ua: "(оновлюються при натисканні на кнопку - Перевірити)",
    },
    subtitle: {
      ru: "Результат выполнения кода:",
      ua: "Результат виконання коду:",
    },
  },

  SelectCurrentQuestion: {
    text: {
      ru: "Вопрос",
      ua: "Питання",
    },
  },

  TaskButtons: {
    btnCheck: {
      ru: "Проверить",
      ua: "Перевірити",
    },
    btnNextQuestion: {
      ru: "Следующий вопрос",
      ua: "Наступне питання",
    },
    btnEnd: {
      ru: "Завершить",
      ua: "Завершити",
    },
    btnRefresh: {
      ru: "Сбросить",
      ua: "Скинути",
    },
    btnVideo: {
      ru: "Видеоподсказка",
      ua: "Відеопідказка",
    },
    btnShowResultArea: {
      ru: "Показать секцию результатов",
      ua: "Показати секцію результатів",
    },
    btnHideResultArea: {
      ru: "Скрыть секцию результатов",
      ua: "Приховати секцію результатів",
    },
  },

  Timer: {
    text: {
      ru: "Доступ к видеоподсказке через ",
      ua: "Доступ до відеопідказки через ",
    },
  },

  Toastify: {
    complitedText: {
      ru: "Это задание уже решено!",
      ua: "Це завдання вже виконане!",
    },
    complitedHalfMarathonText: {
      ru: "Ого! Вы решили уже половину задач, так держать! :)",
      ua: "Ого! Ви вирішили вже половину завдань, так тримати! :)",
    },
    almostComplitedMarathonText: {
      ru: "Круто: вы уже на финишной прямой, впереди последнее задание!",
      ua: "Круто: ви вже на фінішній прямій, попереду останнє завдання!",
    },
  },

  ModalInfo: {
    title: {
      ru: "Поздравляем!",
      ua: "Вітаємо!",
    },
    text1: {
      ru: "Игра началась ",
      ua: "Гра почалась ",
    },
  },
};
